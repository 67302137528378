import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { colors } from "~/utils/colors";
import { Link } from "gatsby";

type Props = {
  data: {
    contentfulDownload: GatsbyTypes.ContentfulDownload;
  };
  pageContext: {
    contentfulId: string;
  };
};

const ClosedDownloadForm: React.FC<Props> = ({ data }) => {
  const { documentTitle } = data.contentfulDownload;

  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${colors.white};
          padding: 0 30px;
          margin: 30px auto 50px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          max-width: 500px;
        }
        .title {
          font-size: 24px;
          margin: 0 0 20px;
        }
        .description {
          font-size: 14px;
          margin: 0 0 20px;
        }
        .toTopLink {
          display: flex;
        }
        .toTopLink :global(a) {
          font-size: 14px;
          text-decoration: underline;
        }
      `}</style>
      <Layout title={`公開終了: ${documentTitle}`}>
        <div className="wrapper">
          <h1 className="title">{documentTitle}</h1>
          <p className="description">
            弊社資料に興味をもっていただき、ありがとうございます。
            <br />
            {`誠に申し訳ございませんが、${documentTitle}の公開を終了いたしました。`}
          </p>
          <div className="toTopLink">
            <Link to="/">トップページへ戻る</Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ClosedDownloadForm;

export const closedDownloadFormQuery = graphql`
  query ClosedDownloadForm($contentfulId: String) {
    contentfulDownload(contentful_id: { eq: $contentfulId }) {
      id
      title
      documentTitle
    }
  }
`;
